define(function(require) {
	var Backbone = require('backbone'),
			SessionModel = require('models/session'),
			PNotify = require('libs/pnotify'),
			i18next = require('i18next'),
			LoginTemplate = require('templates/login.html'),
			XHR = require('i18next-xhr-backend');

			require('assets/scss/login.scss');
			window.i18next = i18next;

	var LoginView = Backbone.View.extend({
		template: _.template(LoginTemplate),

		initialize: function(options) {
			this.options = options || {};
			this.isMobile = $(document).find('html').attr('ismobile');
			this.model = new SessionModel();
			this.lang = this.options.lang;
			this.render();
		},

		login: function(ev) {
			ev.preventDefault(ev);
			ev.stopPropagation();
			this.$('#login').addClass('disabled');
			var self = this;
			var email = this.$('#email').val(),
				password = this.$('#pass').val();

			this.model.login(email, password, true, this.onLoginError.bind(this));
		},

		onLoginError: function(err) {
			this.$('#login').removeClass('disabled');
			new PNotify({
				title:__('Wrong email or password!'),
				text: __('Check your entered email or password'),
				type: 'error',
				delay: 5000,
				icon: 'icon-48 lock',
				styling: 'bootstrap3'
			});

			this.$('#pass').val('');
		},

		unbindEvents: function() {
			this.$('#login').off('click');
		},

		bindEvents: function() {
			this.$('#login').on('click', _.bind(this.login, this));
		},

		render: function() {
			this.unbindEvents();
			this.$el.html(this.template({
				lang: this.lang,
				isMobile: this.isMobile
			}));
			this.bindEvents();
		}
	});

	$(document).ready(function() {
		var lang = $(this).find('html').attr('lang');
		i18next
		.use(XHR)
		.init({
			debug: true,
			lng: lang,
			fallbackLng: false,
			keySeparator: false,
			nsSeparator: false,
			backend: {
				loadPath: "/locales/{{lng}}/{{ns}}.json"
			}
		}, (function(err, t) {
			window.__ = t;
			new LoginView({
				el: '.loginWrapper',
				lang: lang
			});
		}));
	});
});
