define(function(require) {
	var $ = require('jquery'),
			_ = require('underscore'),
			Backbone = require('backbone'),

	SessionModel = Backbone.Model.extend({

		initialize: function() {
		},

		register: function(email, password, name, errorCallback) {
				var url = '/register',
					onError = errorCallback;

				var formValues = {
					email: email,
					name: name,
					password: password,
				};

				$.ajax({
					url: url,
					type: 'POST',
					dataType: 'json',
					data: formValues,
					success: function() {
							window.location.href = '/';
					},
					error: function(xhr) {
						if(_.isFunction(onError)) {
							onError(xhr);
						}
					}
				});
		},

		login: function(email, password, redirect, errorCallback) {
			var url = '/auth/login',
				onError = errorCallback;

			console.log('Loggin in... ');

			var formValues = {
				email: email,
				password: password,
			};

			$.ajax({
				url: url,
				type: 'POST',
				dataType: 'json',
				data: formValues,
				success: function() {
					console.log('Loggin in successful!');

					if (redirect) {
						window.location.href = '/';
					}
				},
				error: function(xhr) {
					if(_.isFunction(onError)) {
						onError(xhr);
					}
					console.log('Loggin in failed!');
				}
			});
		}
	});

	return SessionModel;
});
